<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		bgColor?: string;
		bgImg?: string;
		bgImg2x?: string;
		type?: string;
	}>(),
	{
		bgColor: "",
		type: ""
	}
);

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const background = props.bgColor;
const imagePattern = `url('${baseImageUrl}${props.bgImg}')`;
const imagePattern2x = `url('${baseImageUrl}${props.bgImg2x}')`;
</script>

<template>
	<section class="app-banner section--top-slider">
		<div class="slide-item app-banner__content">
			<div>
				<slot />
			</div>
			<div class="app-banner__desc">
				<slot name="description" />
			</div>
			<div class="app-banner__actions">
				<slot name="actions" />
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
.app-banner {
	color: var(--cannes);
	position: relative;
	cursor: pointer;
	height: 332px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: v-bind(background);
	background-image: v-bind(imagePattern);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 100%;
	@include media-breakpoint-down(md) {
		background-image: v-bind(imagePattern2x);
		border-radius: 10px;
		height: 433px;
	}

	&__content {
		z-index: 5;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		max-width: none;
		height: 100%;

		@include media-breakpoint-down(md) {
			padding-top: gutter(5);
			justify-content: center;
			align-items: center;
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: gutter(2.5);

		@include media-breakpoint-down(md) {
			margin-top: gutter(1);
		}
	}

	&__desc {
		color: var(--clara);
		display: flex;
		flex-direction: column;
		gap: gutter(0.5);
		text-align: center;
		margin-top: gutter(0.5);

		@include media-breakpoint-down(md) {
			margin-top: 0;
		}
	}
}

:deep {
	p {
		margin: 0;
	}
	.banner {
		&-title {
			color: var(--coro);
		}
	}

	.socials {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: gutter(2);

		&__buttons {
			display: flex;
			align-items: center;
			gap: gutter(2);
		}
	}

	.app-banner {
		&__btn {
			width: 280px;
			min-width: max-content;

			@include media-breakpoint-down(md) {
				width: auto;
			}

			&__socials {
				width: 48px;
				height: 48px;
				text-align: center;
				vertical-align: middle;

				@include media-breakpoint-down(md) {
					width: 40px;
					height: 40px;
				}
			}
		}

		&__desc-money {
			display: flex;
			align-items: center;
			height: 38px;

			@include media-breakpoint-down(md) {
				margin-top: 0;
				height: 33px;
				justify-content: center;
			}
		}

		&__desc-ico {
			width: 26px;
			height: 26px;
			margin-right: gutter(0.5);
		}
		&__sum {
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			color: var(--clara);

			@include media-breakpoint-down(md) {
				font-size: 28px;
			}
		}
	}
}
</style>
